import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const fetchCategories = async () => {
  const response = await axios.get('https://back.eatplay.kz/api/categories');
  return response.data;
};

export const useCategories = () => {
  return useQuery({
    queryKey: ['categories'],
    queryFn: fetchCategories,
  });
};

const createCategory = async (data) => {
  const response = await axios.post('https://back.eatplay.kz/api/categories', data);
  return response.data;
};

export const useCreateCategory = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createCategory,
    onSuccess: () => {
      queryClient.invalidateQueries(['categories']);
    },
  });
};

const updateCategory = async ({ id, data }) => {
  const response = await axios.put(`https://back.eatplay.kz/api/categories/${id}`, data);
  return response.data;
};

export const useUpdateCategory = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateCategory,
    onSuccess: () => {
      queryClient.invalidateQueries(['categories']);
    },
  });
};

const deleteCategory = async (id) => {
  await axios.delete(`https://back.eatplay.kz/api/categories/${id}`);
};

export const useDeleteCategory = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteCategory,
    onSuccess: () => {
      queryClient.invalidateQueries(['categories']);
    },
  });
};

export default useCategories;
