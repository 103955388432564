import React from 'react'
import GetDishes from '../components/GetDishes'

const Dishes = () => {
  return (
    <div className='container_dishes'> 
        <GetDishes/>
    </div>  )
}

export default Dishes