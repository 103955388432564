import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaStar } from 'react-icons/fa';
import { useFetchRestaurants, useDeleteRestaurant, useCreateRestaurant } from '../../hooks/restaurantsAPI';
import RestaurantModal from '../Modals/RestaurantModal';
import NewRestaurantModal from '../Modals/NewRestaurantModal';
import { IoSettings } from "react-icons/io5";

const Restaurants = () => {
  const { data, error, isLoading } = useFetchRestaurants();
  const deleteMutation = useDeleteRestaurant();
  const createMutation = useCreateRestaurant();

  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNewRestaurantModalOpen, setIsNewRestaurantModalOpen] = useState(false);

  const handleDelete = (id) => {
    if (window.confirm('Вы действительно хотите удалить ресторан?')) {
      deleteMutation.mutate(id);
    }
  };

  const handleOpenModal = (restaurant) => {
    setSelectedRestaurant(restaurant);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedRestaurant(null);
  };

  const handleOpenNewRestaurantModal = () => {
    setIsNewRestaurantModalOpen(true);
  };

  const handleCloseNewRestaurantModal = () => {
    setIsNewRestaurantModalOpen(false);
  };

  if (isLoading) return <div className="text-center text-lg">Loading...</div>;
  if (error) return <div className="text-center text-lg text-red-600">Error loading data</div>;

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold mb-4">Рестораны</h1>
      <button
        onClick={handleOpenNewRestaurantModal}
        className="mb-4 bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition-colors duration-300"
      >
        Добавить новый ресторан
      </button>
      <ul className="space-y-4">
        {data.map(restaurant => (
          <li
            key={restaurant.id}
            className="flex items-center p-4 bg-white shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300"
          >
            <img
              src={restaurant.banner}
              alt={restaurant.title}
              className="w-1/3 h-32 object-cover rounded-lg mr-4 cursor-pointer"
              onClick={() => handleOpenModal(restaurant)}
            />
            <div className="w-2/3">
              <Link to={`/restaurants/${restaurant.id}`}>
                <h2 className="text-xl font-semibold mb-2">{restaurant.title}</h2>
              </Link>
              <p className="text-gray-600 mb-2">{restaurant.description}</p>
              <div className="flex items-center text-yellow-500">
                <FaStar className="mr-1" />
                <span>{restaurant.rating || '4.5'}</span>
              </div>
              <button
                onClick={() => handleDelete(restaurant.id)}
                className="mt-2 bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-colors duration-300"
              >
                Удалить
              </button>
            </div>
          
          {console.log(restaurant)}

            <div className='add_preferences&dishes cursor-pointer '>
              <Link to={`/addsetings/${restaurant.id}`}>
                <IoSettings />
              </Link>
            </div>
          </li>
        ))}
      </ul>
      {isModalOpen && (
        <RestaurantModal restaurant={selectedRestaurant} onClose={handleCloseModal} />
      )}
      {isNewRestaurantModalOpen && (
        <NewRestaurantModal onClose={handleCloseNewRestaurantModal} />
      )}
    </div>
  );
};

export default Restaurants;
