import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const fetchPromotions = async () => {
  const response = await axios.get('https://back.eatplay.kz/api/promotions');
  return response.data;
};

export const useFetchPromotions = () => {
  return useQuery({
    queryKey: ['promotions'],
    queryFn: fetchPromotions,
  });
};

const createPromotion = async (data) => {
  const response = await axios.post('https://back.eatplay.kz/api/promotions', data);
  return response.data;
};

export const useCreatePromotion = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createPromotion,
    onSuccess: () => {
      queryClient.invalidateQueries(['promotions']);
    },
  });
};

const updatePromotion = async ({ id, data }) => {
  const response = await axios.put(`https://back.eatplay.kz/api/promotions/${id}`, data);
  return response.data;
};

export const useUpdatePromotion = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updatePromotion,
    onSuccess: () => {
      queryClient.invalidateQueries(['promotions']);
    },
  });
};

const deletePromotion = async (id) => {
  await axios.delete(`https://back.eatplay.kz/api/promotions/${id}`);
};

export const useDeletePromotion = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deletePromotion,
    onSuccess: () => {
      queryClient.invalidateQueries(['promotions']);
    },
  });
};
