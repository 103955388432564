import React from 'react'
import GetRestaurantsItems from '../components/Restaurant/GetRestaurantItems'

const Restaurants = () => {
  return (
    <div className='container_restaurants'> 
        <span>Настройка ретсоранов</span>
        <GetRestaurantsItems/>
    </div>
  )
}

export default Restaurants