import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import useCategories from '../../hooks/categoriesAPI';

const DishEditModal = ({ dish, onClose, refetchDishes }) => {
  const [formData, setFormData] = useState({
    title: dish?.title || '',
    description: dish?.description || '',
    price: dish?.price || '',
    image: dish?.image || '',
    category_id: dish?.category_id || '',
  });
  const [imagePreview, setImagePreview] = useState(dish?.image || '');
  const [isModified, setIsModified] = useState(false);

  const { data: categories, error: categoriesError, isLoading: isCategoriesLoading } = useCategories();

  const updateDishMutation = useMutation({
    mutationFn: async (updatedData) => {
      const response = await axios.put(`https://back.eatplay.kz/api/dishes/${dish.id}`, updatedData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      return response.data;
    },
    onSuccess: () => {
      refetchDishes();
      onClose();
      if (isModified) {
        alert('Блюдо успешно обновлено!');
      }
    },
    onError: (error) => {
      console.error('Ошибка при обновлении блюда:', error);
      alert('Ошибка при обновлении блюда');
    },
  });

  useEffect(() => {
    setIsModified(
      Object.keys(formData).some(
        key => formData[key] !== (dish && dish[key])
      )
    );
  }, [formData, dish]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setFormData((prev) => ({ ...prev, image: file }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedData = new FormData();
    Object.keys(formData).forEach(key => {
      updatedData.append(key, formData[key]);
    });

    updateDishMutation.mutate(updatedData);
  };

  if (!dish) return null;

  if (isCategoriesLoading) {
    return <div>Загрузка категорий...</div>;
  }

  if (categoriesError) {
    return <div>Ошибка при загрузке категорий: {categoriesError.message}</div>;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-4xl w-full relative flex flex-row">
        <motion.button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          &times;
        </motion.button>
        <div className="flex-none w-1/3 pr-4">
          <input
            type="file"
            name="image"
            accept="image/*"
            onChange={handleFileChange}
            className="hidden"
          />
          <img
            src={imagePreview}
            alt={dish.title}
            className="w-full h-48 object-cover rounded-lg mb-4 cursor-pointer"
            onClick={() => document.querySelector('input[name="image"]').click()}
          />
        </div>
        <div className="flex-1">
          <h2 className="text-2xl font-bold mb-4">Редактировать блюдо</h2>
          <form onSubmit={handleSubmit} className="flex flex-wrap -mx-2">
            {[
              { label: 'Название', name: 'title', type: 'text' },
              { label: 'Описание', name: 'description', type: 'textarea' },
              { label: 'Цена', name: 'price', type: 'number' },
              { label: 'ID категории', name: 'category_id', type: 'select' },
            ].map(({ label, name, type }) => (
              <div key={name} className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-gray-700">{label}</label>
                {type === 'textarea' ? (
                  <textarea
                    name={name}
                    value={formData[name]}
                    onChange={handleChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                  />
                ) : type === 'select' ? (
                  <select
                    name={name}
                    value={formData[name]}
                    onChange={handleChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                  >
                    <option value="">Выберите категорию</option>
                    {categories && categories.map(category => (
                      <option key={category.id} value={category.id}>{category.name}</option>
                    ))}
                  </select>
                ) : (
                  <input
                    type={type}
                    name={name}
                    value={formData[name]}
                    onChange={handleChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                  />
                )}
              </div>
            ))}
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-50 w-full"
            >
              Сохранить
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DishEditModal;
