import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';

// Функция для получения всех блюд
const fetchDishes = async () => {
  const response = await axios.get('https://back.eatplay.kz/api/dishes');
  return response.data;
};

// Функция для получения всех категорий
const fetchCategories = async () => {
  const response = await axios.get('https://back.eatplay.kz/api/categories');
  return response.data;
};

// Функция для создания нового блюда
const createDish = async (dishData) => {
  const formData = new FormData();
  Object.keys(dishData).forEach(key => formData.append(key, dishData[key]));

  const response = await axios.post('https://back.eatplay.kz/api/dishes', formData);
  return response.data;
};

// Хук для получения всех блюд
export const useDishes = () => {
  return useQuery({
    queryKey: ['dishes'],
    queryFn: fetchDishes,
  });
};

// Хук для получения всех категорий
export const useCategories = () => {
  return useQuery({
    queryKey: ['categories'],
    queryFn: fetchCategories,
  });
};

// Хук для создания нового блюда
export const useCreateDish = () => {
  return useMutation({
    mutationFn: createDish,
  });
};

// Хук для обновления блюда по ID
const updateDish = async ({ id, dishData }) => {
  const formData = new FormData();
  Object.keys(dishData).forEach(key => formData.append(key, dishData[key]));

  const response = await axios.put(`https://back.eatplay.kz/api/dishes/${id}`, formData);
  return response.data;
};

export const useUpdateDish = () => {
  return useMutation({
    mutationFn: updateDish,
  });
};

// Хук для удаления блюда по ID
const deleteDish = async (id) => {
  const response = await axios.delete(`https://back.eatplay.kz/api/dishes/${id}`);
  return response.data;
};

export const useDeleteDish = () => {
  return useMutation({
    mutationFn: deleteDish,
  });
};
