import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const fetchPreferences = async () => {
  const response = await axios.get('https://back.eatplay.kz/api/preferences');
  return response.data;
};

export const useFetchPreferences = () => {
  return useQuery({
    queryKey: ['preferences'],
    queryFn: fetchPreferences,
  });
};

const createPreference = async (data) => {
  const response = await axios.post('https://back.eatplay.kz/api/preferences', data);
  return response.data;
};

export const useCreatePreference = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createPreference,
    onSuccess: () => {
      queryClient.invalidateQueries(['preferences']);
    },
  });
};

const updatePreference = async ({ id, data }) => {
  const response = await axios.put(`https://back.eatplay.kz/api/preferences/${id}`, data);
  return response.data;
};

export const useUpdatePreference = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updatePreference,
    onSuccess: () => {
      queryClient.invalidateQueries(['preferences']);
    },
  });
};

const deletePreference = async (id) => {
  await axios.delete(`https://back.eatplay.kz/api/preferences/${id}`);
};

export const useDeletePreference = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deletePreference,
    onSuccess: () => {
      queryClient.invalidateQueries(['preferences']);
    },
  });
};
