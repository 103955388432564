// src/hooks/restaurantsAPI.js
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

// Функция для получения списка ресторанов
const fetchRestaurants = async () => {
  const response = await axios.get('https://back.eatplay.kz/api/restaurants');
  return response.data;
};

export const useFetchRestaurants = () => {
  return useQuery({
    queryKey: ['restaurants'],
    queryFn: fetchRestaurants,
  });
};

// Функция для создания ресторана
const createRestaurant = async (data) => {
  const response = await axios.post('https://back.eatplay.kz/api/restaurants', data);
  return response.data;
};

// Хук для создания ресторана
export const useCreateRestaurant = () => {
  return useMutation({
    mutationFn: createRestaurant,
  });
};

// Функция для удаления ресторана
const deleteRestaurant = async (id) => {
  await axios.delete(`https://back.eatplay.kz/api/restaurants/${id}`);
};

// Хук для удаления ресторана
export const useDeleteRestaurant = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteRestaurant,
    onSuccess: () => {
      // После успешного удаления обновляем список ресторанов
      queryClient.invalidateQueries(['restaurants']);
    },
  });
};
