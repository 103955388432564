import React, { useState } from 'react';
import useCategories, { useCreateCategory, useUpdateCategory, useDeleteCategory } from '../hooks/categoriesAPI';
import useCities, { useCreateCity, useUpdateCity, useDeleteCity } from '../hooks/citiesAPI';
import { useFetchPreferences, useCreatePreference, useUpdatePreference, useDeletePreference } from '../hooks/preferencesAPI';

const Settings = () => {
  const { data: categories, error: categoriesError, isLoading: isCategoriesLoading } = useCategories();
  const { data: cities, error: citiesError, isLoading: isCitiesLoading } = useCities();
  const { data: preferences, error: preferencesError, isLoading: isPreferencesLoading } = useFetchPreferences();

  const [newCategory, setNewCategory] = useState('');
  const [updateCategoryId, setUpdateCategoryId] = useState('');
  const [updateCategoryName, setUpdateCategoryName] = useState('');

  const [newCity, setNewCity] = useState('');
  const [updateCityId, setUpdateCityId] = useState('');
  const [updateCityName, setUpdateCityName] = useState('');

  const [newPreference, setNewPreference] = useState('');
  const [updatePreferenceId, setUpdatePreferenceId] = useState('');
  const [updatePreferenceName, setUpdatePreferenceName] = useState('');

  const createCategoryMutation = useCreateCategory();
  const updateCategoryMutation = useUpdateCategory();
  const deleteCategoryMutation = useDeleteCategory();

  const createCityMutation = useCreateCity();
  const updateCityMutation = useUpdateCity();
  const deleteCityMutation = useDeleteCity();

  const createPreferenceMutation = useCreatePreference();
  const updatePreferenceMutation = useUpdatePreference();
  const deletePreferenceMutation = useDeletePreference();

  const handleCreateCategory = () => {
    createCategoryMutation.mutate({ name: newCategory });
    setNewCategory('');
  };

  const handleUpdateCategory = () => {
    updateCategoryMutation.mutate({ id: updateCategoryId, data: { name: updateCategoryName } });
    setUpdateCategoryId('');
    setUpdateCategoryName('');
  };

  const handleDeleteCategory = (id) => {
    deleteCategoryMutation.mutate(id);
  };

  const handleCreateCity = () => {
    createCityMutation.mutate({ name: newCity });
    setNewCity('');
  };

  const handleUpdateCity = () => {
    updateCityMutation.mutate({ id: updateCityId, data: { name: updateCityName } });
    setUpdateCityId('');
    setUpdateCityName('');
  };

  const handleDeleteCity = (id) => {
    deleteCityMutation.mutate(id);
  };

  const handleCreatePreference = () => {
    createPreferenceMutation.mutate({ preference_name: newPreference });
    setNewPreference('');
  };

  const handleUpdatePreference = () => {
    updatePreferenceMutation.mutate({ id: updatePreferenceId, data: { preference_name: updatePreferenceName } });
    setUpdatePreferenceId('');
    setUpdatePreferenceName('');
  };

  const handleDeletePreference = (id) => {
    deletePreferenceMutation.mutate(id);
  };

  if (isCategoriesLoading || isCitiesLoading || isPreferencesLoading) {
    return <div className="text-center py-6">Загрузка...</div>;
  }

  if (categoriesError || citiesError || preferencesError) {
    return <div className="text-center py-6 text-red-500">Ошибка при загрузке данных.</div>;
  }

  return (
    <div className="container mx-auto p-6">
      <ul className="mb-6">
        {categories && categories.map(category => (
          <li key={category.id} className="flex justify-between items-center p-2 border-b border-gray-200">
            {category.name}
            <div>
              <button
                onClick={() => handleDeleteCategory(category.id)}
                className="text-red-500 hover:text-red-700 mx-2"
              >
                Удалить
              </button>
              <button
                onClick={() => {
                  setUpdateCategoryId(category.id);
                  setUpdateCategoryName(category.name);
                }}
                className="text-blue-500 hover:text-blue-700 mx-2"
              >
                Изменить
              </button>
            </div>
          </li>
        ))}
      </ul>
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-2">Добавить новую категорию</h3>
        <div className="flex items-center">
          <input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            className="border p-2 rounded mr-2 flex-1"
          />
          <button
            onClick={handleCreateCategory}
            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-700"
          >
            Добавить
          </button>
        </div>
      </div>
      {updateCategoryId && (
        <div className="mb-6">
          <h3 className="text-xl font-bold mb-2">Обновить категорию</h3>
          <div className="flex items-center">
            <input
              type="text"
              value={updateCategoryName}
              onChange={(e) => setUpdateCategoryName(e.target.value)}
              className="border p-2 rounded mr-2 flex-1"
            />
            <button
              onClick={handleUpdateCategory}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
            >
              Обновить
            </button>
          </div>
        </div>
      )}

      <ul className="mb-6">
        {cities && cities.map(city => (
          <li key={city.id} className="flex justify-between items-center p-2 border-b border-gray-200">
            {city.name}
            <div>
              <button
                onClick={() => handleDeleteCity(city.id)}
                className="text-red-500 hover:text-red-700 mx-2"
              >
                Удалить
              </button>
              <button
                onClick={() => {
                  setUpdateCityId(city.id);
                  setUpdateCityName(city.name);
                }}
                className="text-blue-500 hover:text-blue-700 mx-2"
              >
                Изменить
              </button>
            </div>
          </li>
        ))}
      </ul>
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-2">Добавить новый город</h3>
        <div className="flex items-center">
          <input
            type="text"
            value={newCity}
            onChange={(e) => setNewCity(e.target.value)}
            className="border p-2 rounded mr-2 flex-1"
          />
          <button
            onClick={handleCreateCity}
            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-700"
          >
            Добавить
          </button>
        </div>
      </div>
      {updateCityId && (
        <div className="mb-6">
          <h3 className="text-xl font-bold mb-2">Обновить город</h3>
          <div className="flex items-center">
            <input
              type="text"
              value={updateCityName}
              onChange={(e) => setUpdateCityName(e.target.value)}
              className="border p-2 rounded mr-2 flex-1"
            />
            <button
              onClick={handleUpdateCity}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
            >
              Обновить
            </button>
          </div>
        </div>
      )}

      <ul className="mb-6">
        {preferences && preferences.map(preference => (
          <li key={preference.id} className="flex justify-between items-center p-2 border-b border-gray-200">
            {preference.preference_name}
            <div>
              <button
                onClick={() => handleDeletePreference(preference.id)}
                className="text-red-500 hover:text-red-700 mx-2"
              >
                Удалить
              </button>
              <button
                onClick={() => {
                  setUpdatePreferenceId(preference.id);
                  setUpdatePreferenceName(preference.preference_name);
                }}
                className="text-blue-500 hover:text-blue-700 mx-2"
              >
                Изменить
              </button>
            </div>
          </li>
        ))}
      </ul>
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-2">Добавить новое предпочтение</h3>
        <div className="flex items-center">
          <input
            type="text"
            value={newPreference}
            onChange={(e) => setNewPreference(e.target.value)}
            className="border p-2 rounded mr-2 flex-1"
          />
          <button
            onClick={handleCreatePreference}
            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-700"
          >
            Добавить
          </button>
        </div>
      </div>
      {updatePreferenceId && (
        <div className="mb-6">
          <h3 className="text-xl font-bold mb-2">Обновить предпочтение</h3>
          <div className="flex items-center">
            <input
              type="text"
              value={updatePreferenceName}
              onChange={(e) => setUpdatePreferenceName(e.target.value)}
              className="border p-2 rounded mr-2 flex-1"
            />
            <button
              onClick={handleUpdatePreference}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
            >
              Обновить
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Settings;
