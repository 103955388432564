import React, { useState } from 'react';
import { useFetchPromotions, useCreatePromotion, useUpdatePromotion, useDeletePromotion } from '../hooks/promotionAPI';

const Promotions = () => {
  const { data: promotions, error: promotionsError, isLoading: isPromotionsLoading } = useFetchPromotions();
  const [newDescription, setNewDescription] = useState('');
  const [newImage, setNewImage] = useState(null);
  const [selectedPromotion, setSelectedPromotion] = useState(null);
  const [updateDescription, setUpdateDescription] = useState('');
  const [updateImage, setUpdateImage] = useState(null);

  const createPromotionMutation = useCreatePromotion();
  const updatePromotionMutation = useUpdatePromotion();
  const deletePromotionMutation = useDeletePromotion();

  const handleCreatePromotion = () => {
    if (newDescription.trim() === '' || !newImage) return;
    const formData = new FormData();
    formData.append('description', newDescription);
    formData.append('image', newImage);
    createPromotionMutation.mutate(formData);
    setNewDescription('');
    setNewImage(null);
  };

  const handleUpdatePromotion = () => {
    if (!selectedPromotion) return;
    const formData = new FormData();
    formData.append('description', updateDescription);
    if (updateImage) {
      formData.append('image', updateImage);
    }
    updatePromotionMutation.mutate({ id: selectedPromotion.id, data: formData });
    setSelectedPromotion(null);
    setUpdateDescription('');
    setUpdateImage(null);
  };

  const handleDeletePromotion = (id) => {
    deletePromotionMutation.mutate(id);
  };

  const handleEditPromotion = (promotion) => {
    setSelectedPromotion(promotion);
    setUpdateDescription(promotion.description);
  };

  if (isPromotionsLoading) {
    return <div className="text-center py-6">Загрузка...</div>;
  }

  if (promotionsError) {
    return <div className="text-center py-6 text-red-500">Ошибка при загрузке данных.</div>;
  }

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-2xl font-bold mb-4">Промоакции</h2>
      <ul className="mb-6">
        {promotions && promotions.map(promotion => (
          <li key={promotion.id} className="p-2 border-b border-gray-200 flex justify-between items-center">
            <div>
              <p>{promotion.description}</p>
              <img src={promotion.image} alt={promotion.description} className="w-20 h-20 object-cover" />
            </div>
            <div>
              <button
                onClick={() => handleEditPromotion(promotion)}
                className="bg-yellow-500 text-white px-4 py-2 rounded mr-2"
              >
                Редактировать
              </button>
              <button
                onClick={() => handleDeletePromotion(promotion.id)}
                className="bg-red-500 text-white px-4 py-2 rounded"
              >
                Удалить
              </button>
            </div>
          </li>
        ))}
      </ul>

      <div className="mb-4">
        <input
          type="text"
          placeholder="Новое описание"
          value={newDescription}
          onChange={(e) => setNewDescription(e.target.value)}
          className="border p-2 rounded w-full mb-2"
        />
        <input
          type="file"
          onChange={(e) => setNewImage(e.target.files[0])}
          className="border p-2 rounded w-full mb-2"
        />
        <button
          onClick={handleCreatePromotion}
          className="bg-blue-500 text-white px-4 py-2 rounded w-full"
        >
          Добавить промоакцию
        </button>
      </div>

      {selectedPromotion && (
        <div className="mb-4">
          <h3 className="text-xl font-bold mb-2">Обновить промоакцию</h3>
          <input
            type="text"
            placeholder="Новое описание"
            value={updateDescription}
            onChange={(e) => setUpdateDescription(e.target.value)}
            className="border p-2 rounded w-full mb-2"
          />
          <input
            type="file"
            onChange={(e) => setUpdateImage(e.target.files[0])}
            className="border p-2 rounded w-full mb-2"
          />
          <button
            onClick={handleUpdatePromotion}
            className="bg-green-500 text-white px-4 py-2 rounded w-full"
          >
            Обновить промоакцию
          </button>
        </div>
      )}
    </div>
  );
};

export default Promotions;
