import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const AddSettings = () => {
  const { id } = useParams();
  const [restaurant, setRestaurant] = useState(null);
  const [cityId, setCityId] = useState('');
  const [dishId, setDishId] = useState('');
  const [preferenceId, setPreferenceId] = useState('');
  const [promotionId, setPromotionId] = useState('');
  const [cities, setCities] = useState([]);
  const [dishes, setDishes] = useState([]);
  const [preferences, setPreferences] = useState([]);
  const [promotions, setPromotions] = useState([]);

  const fetchOptions = async () => {
    try {
      const [citiesRes, dishesRes, preferencesRes, promotionsRes] = await Promise.all([
        axios.get('https://back.eatplay.kz/api/cities'),
        axios.get('https://back.eatplay.kz/api/dishes'),
        axios.get('https://back.eatplay.kz/api/preferences'),
        axios.get('https://back.eatplay.kz/api/promotions'),
      ]);

      setCities(citiesRes.data);
      setDishes(dishesRes.data);
      setPreferences(preferencesRes.data);
      setPromotions(promotionsRes.data);
    } catch (error) {
      console.error('Error fetching options:', error);
    }
  };

  useEffect(() => {
    const fetchRestaurant = async () => {
      try {
        const response = await axios.get(`https://back.eatplay.kz/api/restaurants/${id}`);
        setRestaurant(response.data);
      } catch (error) {
        console.error('Error fetching restaurant:', error);
      }
    };

    fetchRestaurant();
    fetchOptions();
  }, [id]);

  const handleAddCity = async () => {
    try {
      await axios.post(`https://back.eatplay.kz/api/restaurants/${id}/cities/${cityId}`);
      alert('Город успешно добавлен к ресторану!');
      setCityId('');
      fetchOptions(); 
    } catch (error) {
      console.error('Error adding city:', error);
    }
  };

  const handleAddDish = async () => {
    try {
      await axios.post(`https://back.eatplay.kz/api/restaurants/${id}/dishes/${dishId}`);
      alert('Блюдо успешно добавлено к ресторану!');
      setDishId(''); 
      fetchOptions(); 
    } catch (error) {
      console.error('Error adding dish:', error);
    }
  };

  const handleAddPreference = async () => {
    try {
      await axios.post(`https://back.eatplay.kz/api/restaurants/${id}/preferences/${preferenceId}`);
      alert('Предпочтение успешно добавлено к ресторану!');
      setPreferenceId(''); 
      fetchOptions(); 
    } catch (error) {
      console.error('Error adding preference:', error);
    }
  };

  const handleAddPromotion = async () => {
    try {
      await axios.post(`https://back.eatplay.kz/api/restaurants/${id}/promotions/${promotionId}`);
      alert('Акция успешно добавлена к ресторану!');
      setPromotionId(''); 
      fetchOptions(); 
    } catch (error) {
      console.error('Error adding promotion:', error);
    }
  };

  const handleDeleteCity = async (cityIdToDelete) => {
    try {
      await axios.delete(`https://back.eatplay.kz/api/restaurants/${id}/cities/${cityIdToDelete}`);
      alert('Город успешно удален из ресторана!');
      setCities(cities.filter(city => city.id !== cityIdToDelete)); 
    } catch (error) {
      console.error('Error deleting city:', error);
    }
  };

  const handleDeleteDish = async (dishIdToDelete) => {
    try {
      await axios.delete(`https://back.eatplay.kz/api/restaurants/${id}/dishes/${dishIdToDelete}`);
      alert('Блюдо успешно удалено из ресторана!');
      setDishes(dishes.filter(dish => dish.id !== dishIdToDelete)); 
    } catch (error) {
      console.error('Error deleting dish:', error);
    }
  };

  const handleDeletePreference = async (preferenceIdToDelete) => {
    try {
      await axios.delete(`https://back.eatplay.kz/api/restaurants/${id}/preferences/${preferenceIdToDelete}`);
      alert('Предпочтение успешно удалено из ресторана!');
      setPreferences(preferences.filter(preference => preference.id !== preferenceIdToDelete)); 
    } catch (error) {
      console.error('Error deleting preference:', error);
    }
  };

  const handleDeletePromotion = async (promotionIdToDelete) => {
    try {
      await axios.delete(`https://back.eatplay.kz/api/restaurants/${id}/promotions/${promotionIdToDelete}`);
      alert('Акция успешно удалена из ресторана!');
      setPromotions(promotions.filter(promotion => promotion.id !== promotionIdToDelete)); 
    } catch (error) {
      console.error('Error deleting promotion:', error);
    }
  };

  if (!restaurant) {
    return <div>Loading...</div>;
  }

  return (
    <div className='container'>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Рестораны</th>
            <th className="py-2 px-4 border-b">Настройки</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="py-2 px-4 border-b">Название</td>
            <td className="py-2 px-4 border-b">{restaurant.title}</td>
          </tr>
          <tr>
            <td className="py-2 px-4 border-b">Баннер</td>
            <td className="py-2 px-4 border-b">
              <img src={restaurant.banner} alt={restaurant.title} className="w-32 h-32 object-cover" />
            </td>
          </tr>
        </tbody>
      </table>

      <div className="mt-4">
        <h2 className="text-xl font-semibold">Добавить город</h2>
        <select 
          value={cityId} 
          onChange={(e) => setCityId(e.target.value)} 
          className="border p-2 mr-2"
        >
          <option value="">Выберите город</option>
          {cities.map(city => (
            <option key={city.id} value={city.id}>{city.name}</option>
          ))}
        </select>
        <button onClick={handleAddCity} className="bg-blue-500 text-white px-4 py-2 rounded-lg">
          Добавить город
        </button>
      </div>

      <div className="mt-4">
        <h2 className="text-xl font-semibold">Добавить блюдо</h2>
        <select 
          value={dishId} 
          onChange={(e) => setDishId(e.target.value)} 
          className="border p-2 mr-2"
        >
          <option value="">Выберите блюдо</option>
          {dishes.map(dish => (
            <option key={dish.id} value={dish.id}>{dish.title}</option>
          ))}
        </select>
        <button onClick={handleAddDish} className="bg-green-500 text-white px-4 py-2 rounded-lg">
          Добавить блюдо
        </button>
      </div>

      <div className="mt-4">
        <h2 className="text-xl font-semibold">Добавить предпочтение</h2>
        <select 
          value={preferenceId} 
          onChange={(e) => setPreferenceId(e.target.value)} 
          className="border p-2 mr-2"
        >
          <option value="">Выберите предпочтение</option>
          {preferences.map(preference => (
            <option key={preference.id} value={preference.id}>{preference.preference_name}</option>
          ))}
        </select>
        <button onClick={handleAddPreference} className="bg-orange-500 text-white px-4 py-2 rounded-lg">
          Добавить предпочтение
        </button>
      </div>

      <div className="mt-4">
        <h2 className="text-xl font-semibold">Добавить акцию</h2>
        <select 
          value={promotionId} 
          onChange={(e) => setPromotionId(e.target.value)} 
          className="border p-2 mr-2"
        >
          <option value="">Выберите акцию</option>
          {promotions.map(promotion => (
            <option key={promotion.id} value={promotion.id}>{promotion.description}</option>
          ))}
        </select>
        <button onClick={handleAddPromotion} className="bg-red-500 text-white px-4 py-2 rounded-lg">
          Добавить акцию
        </button>
      </div>


      {restaurant.cities && restaurant.cities.length > 0 && (
        <div className="mt-4">
          <h2 className="text-xl font-semibold">Города</h2>
          <ul>
            {cities.map(city => (
              <li key={city.id} className="border-b py-2">
                {city.name} 
                <button 
                  onClick={() => handleDeleteCity(city.id)} 
                  className="bg-red-500 text-white px-2 py-1 ml-2 rounded-lg"
                >
                  Удалить
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {restaurant.dishes && restaurant.dishes.length > 0 && (
        <div className="mt-4">
          <h2 className="text-xl font-semibold">Блюда</h2>
          <ul>
            {restaurant.dishes.map(dish => (
              <li key={dish.id} className="border-b py-2">
                {dish.title} 
                <button 
                  onClick={() => handleDeleteDish(dish.id)} 
                  className="bg-red-500 text-white px-2 py-1 ml-2 rounded-lg"
                >
                  Удалить
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {restaurant.preferences && restaurant.preferences.length > 0 && (
        <div className="mt-4">
          <h2 className="text-xl font-semibold">Предпочтения</h2>
          <ul>
            {restaurant.preferences.map(preference => (
              <li key={preference.id} className="border-b py-2">
                {preference.preference_name} 
                <button 
                  onClick={() => handleDeletePreference(preference.id)} 
                  className="bg-red-500 text-white px-2 py-1 ml-2 rounded-lg"
                >
                  Удалить
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {restaurant.promotions && restaurant.promotions.length > 0 && (
        <div className="mt-4">
          <h2 className="text-xl font-semibold">Акции</h2>
          <ul>
            {restaurant.promotions.map(promotion => (
              <li key={promotion.id} className="border-b py-2">
                {promotion.description} 
                <button 
                  onClick={() => handleDeletePromotion(promotion.id)} 
                  className="bg-red-500 text-white px-2 py-1 ml-2 rounded-lg"
                >
                  Удалить
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}


{cities.length > 0 && (
        <div className="mt-4">
          <h2 className="text-xl font-semibold">Города</h2>
          <ul>
            {cities.map(city => (
              <li key={city.id} className="border-b py-2">
                {city.name} 
                <button 
                  onClick={() => handleDeleteCity(city.id)} 
                  className="bg-red-500 text-white px-2 py-1 ml-2 rounded-lg"
                >
                  Удалить
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AddSettings;
