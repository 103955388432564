import React, { useState } from 'react';
import { useDishes } from '../hooks/dishesAPI';
import DishAddModal from '../components/Modals/DishAddModal';
import DishEditModal from '../components/Modals/DishEditModal';
import axios from 'axios';

const GetDishes = () => {
  const { data, error, isLoading, refetch } = useDishes();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedDish, setSelectedDish] = useState(null);

  const handleOpenAddModal = () => {
    setIsAddModalOpen(true);
  };

  const handleCloseAddModal = () => {
    setIsAddModalOpen(false);
  };

  const handleOpenEditModal = (dish) => {
    setSelectedDish(dish);
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedDish(null);
  };

  const handleDeleteDish = async (dishId) => {
    if (window.confirm('Вы уверены, что хотите удалить это меню?')) {
      try {
        await axios.delete(`https://back.eatplay.kz/api/dishes/${dishId}`);
        alert('Меню успешно удалено!');
        refetch(); // Refresh the dishes after deletion
      } catch (error) {
        console.error('Ошибка при удалении меню:', error);
        alert('Произошла ошибка при удалении меню');
      }
    }
  };

  if (isLoading) return <div className="text-center text-gray-500">Loading...</div>;
  if (error) return <div className="text-center text-red-500">Error loading dishes</div>;

  return (
    <>
      <button
        className="mb-4 bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition-colors duration-300"
        onClick={handleOpenAddModal}
      >
        Добавить меню
      </button>
      {isAddModalOpen && <DishAddModal onClose={handleCloseAddModal} refetchDishes={refetch} />}
      {isEditModalOpen && (
        <DishEditModal
          dish={selectedDish}
          onClose={handleCloseEditModal}
          refetchDishes={refetch}
        />
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 p-6">
        {data.map((dish) => (
          <div key={dish.id} className="bg-white rounded-lg shadow-lg overflow-hidden">
            <img
              src={dish.image}
              alt={dish.title}
              className="w-full h-48 object-cover cursor-pointer"
              onClick={() => handleOpenEditModal(dish)}
            />
            <div className="p-4">
              <h2 className="text-xl font-bold mb-2">{dish.title}</h2>
              <p className="text-gray-700 mb-4">{dish.description}</p>
              <div className="flex items-center justify-between">
                <span className="text-gray-500">Category: {dish.category.name}</span>
                <span className="text-xl font-semibold">{`${dish.price}`}</span>
              </div>
              <button
                className="mt-2 bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-colors duration-300"
                onClick={() => handleDeleteDish(dish.id)}
              >
                Удалить
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default GetDishes;
