import React from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import Restaurants from './pages/Restaurants';
import Dishes from './pages/Dishes';
import Settings from './pages/Settings';
import Promotions from './components/Promotion';
import AddSettings from './pages/AddSettings';

function App() {
  const location = useLocation();

  return (
    <div className="flex ">
      <nav className="">
        <Link
          to="/restaurants"
          className={`mb-4 text-xl ${
            location.pathname === '/restaurants' ? 'text-white font-bold' : ''
          }`}
        >
          Рестораны
        </Link>
        <Link
          to="/dishes"
          className={`mb-4 text-xl ${
            location.pathname === '/dishes' ? 'text-white font-bold' : ''
          }`}
        >
          Меню блюд
        </Link>
        <Link
          to="/promotions"
          className={`mb-4 text-xl ${
            location.pathname === '/promotions' ? 'text-white font-bold' : ''
          }`}
        >
          Акций и Скидки
        </Link>
        <Link
          to="/settings"
          className={`mb-4 text-xl ${
            location.pathname === '/settings' ? 'text-white font-bold' : ''
          }`}
        >
          Настройки
        </Link>
      </nav>
      <div className=" ">
        <Routes>
          <Route path="restaurants" element={<Restaurants />} />
          <Route path="dishes" element={<Dishes />} />
          <Route path="promotions" element={<Promotions />} />
          <Route path="settings" element={<Settings />} />
          <Route path='addsetings/:id' element={<AddSettings />} />
          </Routes>
      </div>
    </div>
  );
}

export default App;
