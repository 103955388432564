import React, { useState } from 'react';
import { useCreateDish, useCategories } from '../../hooks/dishesAPI';
import noPhoto from '../../assets/no-image.png'

const DishAddModal = ({ onClose, refetchDishes }) => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    price: '',
    category_id: '',
    image: null,
  });
  const [imagePreview, setImagePreview] = useState('');

  const [isImage, setIsImage] = useState(false)

  const { data: categories, isLoading, isError } = useCategories();

  const createDishMutation = useCreateDish();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setFormData((prev) => ({ ...prev, image: file }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createDishMutation.mutate(formData, {
      onSuccess: () => {
        refetchDishes();
        onClose();
        alert('Блюдо успешно добавлено!');
      },
      onError: (error) => {
        console.error('Ошибка при добавлении блюда:', error);
        alert('Ошибка при добавлении блюда. Попробуйте ещё раз.');
      },
    });
  };

  if (isLoading) return <div>Загрузка...</div>;
  if (isError) return <div>Ошибка при загрузке категорий</div>;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-4xl w-full relative flex flex-col">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          &times;
        </button>
        <div className="flex-none mb-4">
          <input
            type="file"
            name="image"
            accept="image/*"
            onChange={handleFileChange}
            className="hidden"
          />
          <img
            src={imagePreview}
            alt={formData.title}
            className="w-full h-48 object-cover rounded-lg cursor-pointer"
            onClick={() => document.querySelector('input[name="image"]').click()}
          />
          <input type='checkbox'/>  нет изображений!
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-700">Название</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700">Описание</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700">Цена</label>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700">Категория</label>
            <select
              name="category_id"
              value={formData.category_id}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
              required
            >
              <option value="">Выберите категорию</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-50"
          >
            Добавить
          </button>
        </form>
      </div>
    </div>
  );
};

export default DishAddModal;
