import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import useCities from '../../hooks/citiesAPI';
import { useDishes } from '../../hooks/dishesAPI';
import { useFetchPreferences } from '../../hooks/preferencesAPI';
import { useFetchPromotions } from '../../hooks/promotionAPI';

const RestaurantModal = ({ restaurant, onClose, refetchRestaurants }) => {
  const [formData, setFormData] = useState({
    title: restaurant?.title || '',
    description: restaurant?.description || '',
    banner: restaurant?.banner || '',
    instagram: restaurant?.instagram || '',
    whatsapp: restaurant?.whatsapp || '',
    o_clock: restaurant?.o_clock || '',
    address: restaurant?.address || '',
    phone_number: restaurant?.phone_number || '',
    tooures: restaurant?.tooures || '',
    preferences: restaurant?.preferences || '',
    city_id: restaurant?.city_id || '',
    dishes: restaurant?.dishes || [],
    promotions: restaurant?.promotions || [],
  });

  const [imagePreview, setImagePreview] = useState(restaurant?.banner || '');
  const [isModified, setIsModified] = useState(false);

  const { data: preferences } = useFetchPreferences();
  const { data: promotions } = useFetchPromotions();
  const { data: dishes } = useDishes();
  const { data: cities } = useCities();

  const updateRestaurantMutation = useMutation({
    mutationFn: async (updatedData) => {
      const response = await axios.put(`https://back.eatplay.kz/api/restaurants/${restaurant.id}`, updatedData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      return response.data;
    },
    onSuccess: () => {
      refetchRestaurants();
      onClose();
      if (isModified) {
        alert('Ресторан успешно обновлён!');
      }
    },
    onError: (error) => {
      console.error('Ошибка при обновлении ресторана:', error);
      alert('Ошибка при обновлении ресторана');
    },
  });

  useEffect(() => {
    setIsModified(
      Object.keys(formData).some(
        key => formData[key] !== (restaurant && restaurant[key])
      )
    );
  }, [formData, restaurant]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setFormData((prev) => ({ ...prev, banner: file }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    const updatedData = new FormData();
    Object.keys(formData).forEach(key => {
      updatedData.append(key, formData[key]);
    });
  
    updateRestaurantMutation.mutate(updatedData);
  };

  if (!restaurant) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-4xl w-full relative flex flex-row">
        <motion.button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          &times;
        </motion.button>
        <div className="flex-none w-1/3 pr-4">
          <input
            type="file"
            name="banner"
            accept="image/*"
            onChange={handleFileChange}
            className="hidden"
          />
          <img
            src={imagePreview}
            alt={restaurant.title}
            className="w-full h-48 object-cover rounded-lg mb-4 cursor-pointer"
            onClick={() => document.querySelector('input[name="banner"]').click()}
          />
        </div>
        <div className="flex-1">
          <h2 className="text-2xl font-bold mb-4">Редактировать ресторан</h2>
          <form onSubmit={handleSubmit} className="flex flex-wrap -mx-2">
            {[
              { label: 'Название', name: 'title', type: 'text' },
              { label: 'Описание', name: 'description', type: 'textarea' },
              { label: 'Instagram', name: 'instagram', type: 'text' },
              { label: 'WhatsApp', name: 'whatsapp', type: 'text' },
              { label: 'Часы работы', name: 'o_clock', type: 'text' },
              { label: 'Адрес', name: 'address', type: 'text' },
              { label: 'Телефон', name: 'phone_number', type: 'text' },
              { label: 'Туры', name: 'tooures', type: 'text' },

            ].map(({ label, name, type, options }) => (
              <div key={name} className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-gray-700">{label}</label>
                {type === 'textarea' ? (
                  <textarea
                    name={name}
                    value={formData[name]}
                    onChange={handleChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                  />
                ) : type === 'select' ? (
                  <select
                    name={name}
                    value={formData[name]}
                    onChange={handleChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                  >
                    <option value="">Выберите опцию</option>
                    {options && options.map(option => (
                      <option key={option.id} value={option.id}>{option.title || option.preference_name || option.description}</option>
                    ))}
                  </select>
                ) : (
                  <input
                    type={type}
                    name={name}
                    value={formData[name]}
                    onChange={handleChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                  />
                )}
              </div>
            ))}

            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-50 w-full"
            >
              Сохранить
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RestaurantModal;