import React, { useState } from 'react';
import { useCreateRestaurant } from '../../hooks/restaurantsAPI';
import useCities from '../../hooks/citiesAPI';

const NewRestaurantModal = ({ onClose }) => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    banner: null,
    instagram: '',
    whatsapp: '',
    o_clock: '',
    address: '',
    phone_number: '',
    tooures: '',
  });
  const [imagePreview, setImagePreview] = useState('');

  const { data: citiesData, isLoading, isError } = useCities();

  const createRestaurantMutation = useCreateRestaurant();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setFormData((prev) => ({ ...prev, banner: file }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newData = new FormData();
    Object.keys(formData).forEach(key => {
      newData.append(key, formData[key] ?? '');
    });

    createRestaurantMutation.mutate(newData, {
      onSuccess: () => {
        onClose();
        alert('Ресторан успешно добавлен!');
      },
      onError: (error) => {
        console.error('Ошибка при добавлении ресторана:', error);
        alert('Ошибка при добавлении ресторана. Попробуйте ещё раз.');
      },
    });
  };

  if (isLoading) return <div>Загрузка...</div>;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          &times;
        </button>
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Добавить новый ресторан</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="flex flex-wrap gap-4 mb-4">
              <div className="flex-1">
                <label className="block text-gray-700">Название</label>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                  required
                />
              </div>
              <div className="flex-1">
                <label className="block text-gray-700">Описание</label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                  required
                />
              </div>
            </div>
            <div className="flex flex-wrap gap-4 mb-4">
              <div className="flex-1">
                <label className="block text-gray-700">Изображение</label>
                <input
                  type="file"
                  name="banner"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="mt-1 block w-full"
                  required
                />
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    className="mt-2 w-full h-48 object-cover rounded-md"
                  />
                )}
              </div>
              <div className="flex-1">
                <label className="block text-gray-700">Instagram</label>
                <input
                  type="text"
                  name="instagram"
                  value={formData.instagram}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                />
              </div>
            </div>
            <div className="flex flex-wrap gap-4 mb-4">
              <div className="flex-1">
                <label className="block text-gray-700">Whatsapp</label>
                <input
                  type="text"
                  name="whatsapp"
                  value={formData.whatsapp}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                />
              </div>
              <div className="flex-1">
                <label className="block text-gray-700">Время работы</label>
                <input
                  type="text"
                  name="o_clock"
                  value={formData.o_clock}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                />
              </div>
            </div>
            <div className="flex flex-wrap gap-4 mb-4">
              <div className="flex-1">
                <label className="block text-gray-700">Адрес</label>
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                />
              </div>
              <div className="flex-1">
                <label className="block text-gray-700">Телефон</label>
                <input
                  type="text"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                />
              </div>
            </div>
            <div className="flex flex-wrap gap-4 mb-4">
              <div className="flex-1">
                <label className="block text-gray-700">Кухня</label>
                <input
                  type="text"
                  name="tooures"
                  value={formData.tooures}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                />
              </div>
              <div className="flex-1">
                <label className="block text-gray-700">Город</label>
                <select
                  name="city_id"
                  value={formData.city_id}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                  required
                >
                  <option value="">Выберите город</option>
                  {citiesData?.map(city => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            >
              Добавить
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewRestaurantModal;
