import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const fetchCities = async () => {
  const response = await axios.get('https://back.eatplay.kz/api/cities');
  return response.data;
};

export const useCities = () => {
  return useQuery({
    queryKey: ['cities'],
    queryFn: fetchCities,
  });
};

const createCity = async (data) => {
  const response = await axios.post('https://back.eatplay.kz/api/cities', data);
  return response.data;
};

export const useCreateCity = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createCity,
    onSuccess: () => {
      queryClient.invalidateQueries(['cities']);
    },
  });
};

const updateCity = async ({ id, data }) => {
  const response = await axios.put(`https://back.eatplay.kz/api/cities/${id}`, data);
  return response.data;
};

export const useUpdateCity = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateCity,
    onSuccess: () => {
      queryClient.invalidateQueries(['cities']);
    },
  });
};

const deleteCity = async (id) => {
  await axios.delete(`https://back.eatplay.kz/api/cities/${id}`);
};

export const useDeleteCity = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteCity,
    onSuccess: () => {
      queryClient.invalidateQueries(['cities']);
    },
  });
};

export default useCities;
